import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import OrderDetail from 'components/OrderDetail'
import { Button, ButtonGroup, Logo, Well, Loading } from '@yapay/design-system'
import Currency from 'components/Currency'
import RestoreScoll from 'components/RestoreScroll'
import Recaptcha from 'components/Recaptcha'

import {
  payWithBalance,
  getBalance,
  resetBalance,
  stepActive,
  PAY_BALANCE_SUCCESS,
} from 'actions'

export class BalancePayment extends Component {
  state = {
    btnText: 'Pagar',
    tokenRecaptcha: '',
    dirtyPayButton: false,
  }

  balanceAfterPayment = () => {
    return this.props.totalBalance - this.props.totalTransaction
  }

  pay = () => {
    this.setState({ dirtyPayButton: true })

    if (!this.state.tokenRecaptcha) {
      return
    }

    this.setState({ btnText: 'Processando...' })

    return this.props
      .payWithBalance({
        ...this.props.payParams,
        token_recaptcha: this.state.tokenRecaptcha,
      })
      .then(resp => {
        this.setState({ btnText: 'Pagar' })
        this.processApiResponse(resp)
      })
  }

  processApiResponse = resp => {
    const { history } = this.props
    if (resp.type === PAY_BALANCE_SUCCESS) {
      ReactGA.event({
        category: 'Balance Payment',
        action: 'Balance Paid',
      })

      return history.replace({
        pathname: `/pagamento/${resp.data.transaction_token}`,
        redirectBalance: true,
      })
    }

    ReactGA.event({
      category: 'Balance Payment',
      action: 'Balance Fail',
    })

    history.replace({
      pathname: `/saldo-falhou`,
      redirectBalance: true,
    })

    return null
  }

  componentWillUnmount() {
    if (this.balanceAfterPayment() > 0) this.props.resetBalance()
  }

  componentDidMount() {
    this.props.stepActive(3)

    if (this.props.sessionToken) {
      this.props.getBalance({
        session_token: this.props.sessionToken,
        seller_token: this.props.sellerToken,
      })
    }
  }

  render() {
    if (!this.props.sessionToken) {
      window.location.href = process.env.REACT_APP_YAPAY_LOGIN
    }

    if (this.props.totalBalance === null) {
      return (
        <div className="text-center">
          <Loading />
        </div>
      )
    }

    if (this.balanceAfterPayment() < 0) {
      return (
        <Redirect
          to={{
            pathname: '/escolha-pagamento',
            state: { balanceNegative: true },
          }}
        />
      )
    }

    return (
      <div className="flex-1">
        <RestoreScoll />
        <h2>Detalhes do pagamento</h2>
        <div className="flex items-center mb-4">
          <span style={{ width: '40px' }} className="mr-2">
            <Logo icon />
          </span>
          <p>Pagamento com saldo Vindi</p>
        </div>

        <div>
          <p className="mb-0 text-sm">Você está pagando com a conta</p>
          <p className="mt-2 mb-4">
            {this.props.payParams.email}
            <a
              href={process.env.REACT_APP_YAPAY_LOGOUT}
              to="#"
              className="p-1 text-sm no-underline hover:text-blue-600"
            >
              (alterar conta)
            </a>
          </p>
        </div>

        <p className="mb-0 text-sm">Saldo disponível</p>
        <h3 className="mt-0">
          <Currency value={this.props.totalBalance} />
        </h3>

        <p className="mb-0 text-sm">Saldo após confirmar o pagamento</p>
        <h3 className="mt-0">
          <Currency value={this.balanceAfterPayment()} />
        </h3>

        <MediaQuery query="(max-width: 767px)">
          <Well className="p-4 md:p-4 -mx-4 mt-8" plain>
            <OrderDetail />
          </Well>
        </MediaQuery>

        <Recaptcha
          onChange={tokenRecaptcha => this.setState({ tokenRecaptcha })}
          invalid={!this.state.tokenRecaptcha && this.state.dirtyPayButton}
          errorMessage="Confirme o reCAPTCHA para continuar"
        />

        <ButtonGroup responsive className="items-center mt-4">
          <Link to="/escolha-pagamento" className="button text-center px-0">
            Voltar
          </Link>
          <Button className="sm:w-64" color="info" onClick={() => this.pay()}>
            {this.state.btnText}
          </Button>
        </ButtonGroup>
      </div>
    )
  }
}

BalancePayment.propTypes = {
  totalBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func,
  totalTransaction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      orderUndefined,
      order: { token, order_token, transaction_token },
    },
    user: { address_token, email, session_token },
    login,
  } = state

  const payParams = {
    token,
    seller_token: state.transaction.seller.token,
    session_token,
    address_token,
    order_token,
    transaction_token,
    email,
    payment_method: 'balance',
    login,
  }

  return {
    orderUndefined,
    payParams,
    totalBalance: state.balance.value,
    totalTransaction: state.transaction.order.total_price,
    sessionToken: state.user.session_token,
    sellerToken: state.transaction.seller.token,
  }
}

export default connect(mapStateToProps, {
  getBalance,
  resetBalance,
  payWithBalance,
  stepActive,
})(BalancePayment)
